import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChatService } from 'src/app/servicios/chat.service';
import { WebsocketsService } from '../../servicios/websockets.service';

@Component({
  selector: 'app-pregunta',
  templateUrl: './pregunta.component.html',
  styleUrls: ['./pregunta.component.scss']
})
export class PreguntaComponent implements OnInit {
 
  constructor(
    private router: Router,
    public chatService: ChatService, 
    public wsService: WebsocketsService
     ) { }

  ngOnInit() {
    if( this.wsService.idEncuesta == ""){
      this.router.navigate(['/registro']);  
    }
    if( this.wsService.idPregunta == ""){
      this.router.navigate(['/espera']);  
    }
    this.chatService.getMessages().subscribe( msg => {
        console.log(msg);
    });
  }

  medirLargo(){
    console.log( this.wsService.respuestas );
  }
  async enviarRespuesta(respuesta: string){
    //console.log("Click: ", respuesta);
    await this.chatService.sendMessage(respuesta);
    //console.log("Respuesta deseada : ", this.wsService.respuestaActual);
    //console.log("Respuesta recibida : ", respuesta);
    if(respuesta == this.wsService.respuestaActual){
      console.log("Respuesta OK");
      this.wsService.correctas++;
    }else{
      console.log("Respuesta Mal");
      this.wsService.incorrectas++;
    }
    this.wsService.idPregunta = ''; 
    this.router.navigate(['/espera']);  
  }
}

import { Component, Input } from '@angular/core';
import { MultiDataSet, Label, Color } from 'ng2-charts';

@Component({
  selector: 'app-dona',
  templateUrl: './dona.component.html',
  styles: [
  ]
})
export class DonaComponent{
  
  @Input() title: string = 'Resultado';

  @Input('labels') doughnutChartLabels: Label[] = ['Correctas', 'Incorrectas'];
  @Input('data') doughnutChartData: MultiDataSet = [
    [0, 0],
  ];

  public colors: Color[] = [
    { backgroundColor: [ '#6857E6','#009FEE' ] }
  ];

}
